import { useMemo } from 'react';
import { useDeviceSize } from './useDeviceSize';
import { useScreenSize } from './useScreenSize';
import * as ScreenSizes from '../layout/constants/ScreenSizes';
const sizes = Object.values(ScreenSizes);
const getNextScreenSize = size => {
  if (size === ScreenSizes.XS) {
    return null;
  }
  return sizes[sizes.indexOf(size) - 1];
};
export function useResponsiveProps(props, useDevice = false) {
  const deviceSize = useDeviceSize();
  const windowSize = useScreenSize();
  const screenSize = useDevice ? deviceSize : windowSize;
  return useMemo(() => {
    return Object.keys(props).reduce((acc, _key) => {
      const key = _key;
      if (typeof props[key] !== 'object') return acc;
      let breakpoint = screenSize;
      while (!(key in acc)) {
        if (breakpoint === null) {
          break;
        } else if (breakpoint in props[key]) {
          acc[key] = props[key][breakpoint];
        } else {
          breakpoint = getNextScreenSize(breakpoint);
        }
      }
      return acc;
    }, {});
  }, [screenSize, props]);
}