'use es6';

const STRING_COLLATOR = window.Intl && typeof window.Intl === 'object' && new Intl.Collator();
export default ((option1, option2) => {
  let comparisonResult = 0;
  if (STRING_COLLATOR) {
    comparisonResult = STRING_COLLATOR.compare(option1, option2);
  } else if (option1 > option2) {
    comparisonResult = 1;
  } else if (option1 > option2) {
    comparisonResult = -1;
  }
  return comparisonResult;
});