import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["attributes", "content", "ratings", "reviewerData", "reply", "employees", "marketplaceIndustry"],
  _excluded2 = ["attributes", "content", "ratings", "reply"];
import I18n from 'I18n';
import produce from 'immer';
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import Reply from './Reply';
import * as ReviewTypes from '../constants/ReviewType';
import { ProfileReviewSteps, ReviewSteps, AppReviewSteps } from '../constants/ReviewSteps';
import { RatingAxes, AppRatingAxes, ThemeRatingAxes, AppQualityRatingsOrder } from '../constants/RatingAxes';
import { REVIEW_TITLE_LIMIT, REVIEW_BODY_MIN, REVIEW_BODY_MAX, PRIVATE_REVIEW_BODY_MAX } from '../constants/ReviewLimits';
import * as ReviewQuestions from '../constants/ReviewQuestions';
import { PREFER_NOT_TO_SAY } from '../constants/CompanySize';
const reviewResponseDefaults = {
  id: null,
  createdAt: null,
  marketplaceIndustry: null,
  employees: null,
  companyName: null,
  showAvatar: true,
  ratings: {},
  content: {},
  reviewerData: {},
  reply: {
    id: null,
    repliedAt: null,
    reply: null,
    replierDisplayName: null,
    avatarUrl: null,
    companyName: null
  },
  attributes: [],
  reviewerDisplayName: null,
  avatarUrl: null,
  offeringVersion: null,
  reviewType: null
};
export default class Review extends createImmutableObjectClass(reviewResponseDefaults, {
  fromJSON: _ref => {
    let {
        attributes,
        content,
        ratings,
        reviewerData,
        reply,
        employees,
        marketplaceIndustry
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    const reviewerDataEmployees = reviewerData && reviewerData.employees || null;
    const reviewerDataMarketplaceIndustry = reviewerData && reviewerData.industry || null;
    return Object.assign({
      entityId: null,
      providerPortalId: null,
      listingName: null,
      portalId: null,
      userId: null,
      ratings: new Map(Object.entries(ratings)),
      content: new Map(Object.entries(content)),
      employees: reviewerDataEmployees || employees,
      marketplaceIndustry: reviewerDataMarketplaceIndustry || marketplaceIndustry,
      reply: reply ? new Reply(reply) : new Reply(),
      archivedReplies: [],
      attributes: attributes.map(attr => new Map(Object.entries(attr))),
      archived: false,
      offeringType: null
    }, rest);
  },
  toJSON: _ref2 => {
    let {
        attributes,
        content,
        ratings,
        reply
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      attributes: attributes.map(attr => Object.fromEntries(attr)),
      content: Object.fromEntries(content),
      ratings: Object.fromEntries(ratings),
      reply: reply.toJSON(),
      reviewerData: {}
    }, rest);
  }
}) {
  static fromArray(data, reviewType) {
    return data.map(review => new Review(Object.assign({}, review, {
      reviewType
    })));
  }
  static toEmployeeString(range) {
    if (!range) {
      return '';
    }
    let rangeKey;
    let formattedMin;
    let formattedMax;
    if (range === PREFER_NOT_TO_SAY) {
      rangeKey = 'undisclosed';
    } else {
      const [__range, min, max] = range.split('_');
      formattedMin = I18n.formatNumber(parseInt(min, 10));
      formattedMax = I18n.formatNumber(parseInt(max, 10));
      rangeKey = min === '1' ? 'single' : max ? 'range' : 'indefinite';
    }
    return I18n.text(`marketplace-ui-reviews.employees.${rangeKey}`, {
      min: formattedMin,
      max: formattedMax
    });
  }
  isStepComplete(reviewType, step, checkedLegalDisclaimer, optionalShareStep) {
    if (reviewType === ReviewTypes.APP) {
      return this.isAppReviewStepComplete(step, checkedLegalDisclaimer, optionalShareStep);
    } else if (reviewType === ReviewTypes.THEME) {
      return this.isAssetReviewStepComplete(step, checkedLegalDisclaimer);
    } else {
      return this.isProfileReviewStepComplete(step, checkedLegalDisclaimer);
    }
  }
  isAppReviewStepComplete(step, checkedLegalDisclaimer, optionalShareStep) {
    switch (step) {
      case ReviewSteps.RATE:
        return AppRatingAxes.every(axis => axis === RatingAxes.SUPPORT_DOCS || !!this.ratings.get(axis));
      case ReviewSteps.SHARE:
        {
          if (optionalShareStep && this.ratings.get(RatingAxes.OVERALL) >= 3 && !this.hasContent()) {
            return true;
          }
          return this.content.get(ReviewQuestions.TITLE) && this.content.get(ReviewQuestions.TITLE).trim().length > 0 && this.content.get(ReviewQuestions.TITLE).trim().length < REVIEW_TITLE_LIMIT && (optionalShareStep || this.content.get(ReviewQuestions.FOR) && this.content.get(ReviewQuestions.FOR).trim().length > 0) && this.content.get(ReviewQuestions.REVIEW) && (optionalShareStep && this.ratings.get(RatingAxes.OVERALL) >= 3 || this.content.get(ReviewQuestions.REVIEW).trim().length >= REVIEW_BODY_MIN) && this.content.get(ReviewQuestions.REVIEW).trim().length <= REVIEW_BODY_MAX;
        }
      case ReviewSteps.ABOUT:
        return this.marketplaceIndustry && this.employees && checkedLegalDisclaimer;
      case AppReviewSteps.PRIVATE:
        return this.content.get(ReviewQuestions.PRIVATE) !== undefined && this.content.get(ReviewQuestions.PRIVATE).trim().length <= PRIVATE_REVIEW_BODY_MAX || this.content.get(ReviewQuestions.PRIVATE) === undefined;
      default:
        return false;
    }
  }
  isAssetReviewStepComplete(step, checkedLegalDisclaimer) {
    switch (step) {
      case ReviewSteps.RATE:
        return ThemeRatingAxes.every(axis => axis === RatingAxes.SUPPORT_DOCS || !!this.ratings.get(axis));
      case ReviewSteps.SHARE:
        return this.content.get(ReviewQuestions.TITLE) && this.content.get(ReviewQuestions.TITLE).trim().length > 0 && this.content.get(ReviewQuestions.TITLE).trim().length < REVIEW_TITLE_LIMIT && this.content.get(ReviewQuestions.CUSTOMIZABLE) && this.content.get(ReviewQuestions.CUSTOMIZABLE).trim().length > 0 && this.content.get(ReviewQuestions.REVIEW) && this.content.get(ReviewQuestions.REVIEW).trim().length >= REVIEW_BODY_MIN && this.content.get(ReviewQuestions.REVIEW).trim().length <= REVIEW_BODY_MAX;
      case ReviewSteps.ABOUT:
        return this.marketplaceIndustry && this.employees && checkedLegalDisclaimer;
      default:
        return false;
    }
  }
  isProfileReviewStepComplete(step, checkedLegalDisclaimer) {
    switch (step) {
      case ProfileReviewSteps.RATE:
        return this.ratings.get(RatingAxes.OVERALL) && this.content.get(ReviewQuestions.TITLE) && this.content.get(ReviewQuestions.TITLE).trim().length > 0 && this.content.get(ReviewQuestions.TITLE).length < REVIEW_TITLE_LIMIT && this.content.get(ReviewQuestions.REVIEW) && this.content.get(ReviewQuestions.REVIEW).length >= REVIEW_BODY_MIN && this.content.get(ReviewQuestions.REVIEW).length <= REVIEW_BODY_MAX && this.content.get(ReviewQuestions.SERVICES) && this.content.get(ReviewQuestions.SERVICES).length;
      case ReviewSteps.SHARE:
        return this.content.get(ReviewQuestions.TITLE) && this.content.get(ReviewQuestions.TITLE).trim().length > 0 && this.content.get(ReviewQuestions.TITLE).length < REVIEW_TITLE_LIMIT && this.content.get(ReviewQuestions.REVIEW) && this.content.get(ReviewQuestions.REVIEW).length >= REVIEW_BODY_MIN && this.content.get(ReviewQuestions.REVIEW).length <= REVIEW_BODY_MAX && this.content.get(ReviewQuestions.SERVICES) && this.content.get(ReviewQuestions.SERVICES).length;
      case ProfileReviewSteps.ABOUT:
        return this.marketplaceIndustry && this.employees && checkedLegalDisclaimer;
      default:
        return false;
    }
  }
  getHelpfulCount() {
    return this.get('attributes').filter(attr => attr.get('helpful')).length;
  }
  toggleHelpful(reviewId, userId, helpful) {
    return produce(this, draftReview => {
      const indexToUpdate = draftReview.get('attributes').findIndex(attr => attr.get('userId') === userId);
      const newAttributes = draftReview.attributes;
      if (indexToUpdate !== -1) {
        newAttributes[indexToUpdate].set('helpful', helpful);
      } else {
        newAttributes.push(new Map([['reviewId', reviewId], ['userId', userId], ['helpful', helpful]]));
      }
      draftReview.set('attributes', newAttributes);
    });
  }
  getQualityRatings() {
    const qualityRatingsMap = produce(this.ratings, ratingsDraft => {
      ratingsDraft.delete(RatingAxes.OVERALL);
    });
    switch (this.reviewType) {
      case ReviewTypes.APP:
        return AppQualityRatingsOrder.reduce((final, key) => {
          return final.set(key, this.ratings.get(key));
        }, new Map());
      default:
        return qualityRatingsMap;
    }
  }
  setOverallRating(overallRating) {
    return produce(this, reviewDraft => {
      reviewDraft.get('ratings').set(RatingAxes.OVERALL, overallRating);
    });
  }
  setEmployees(employeeSize) {
    return produce(this, reviewDraft => {
      reviewDraft.set('employees', employeeSize);
    });
  }
  setIndustry(industry) {
    return produce(this, reviewDraft => {
      reviewDraft.set('marketplaceIndustry', industry);
    });
  }
  hasContent() {
    return this.content.size > 0;
  }
  updateReview(updates) {
    return produce(this, reviewDraft => {
      Object.entries(updates).forEach(([key, value]) => {
        if (key === 'content' || key === 'ratings') {
          value.forEach((updateValue, updateKey) => {
            if (updateValue === '') {
              reviewDraft.get(key).delete(updateKey);
            } else {
              reviewDraft.get(key).set(updateKey, updateValue);
            }
          });
        } else {
          reviewDraft.set(key, value);
        }
      });
    });
  }
}