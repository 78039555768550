import { useMemo } from 'react';
import { GRID_BREAKPOINT_SMALL, GRID_BREAKPOINT_MEDIUM, GRID_BREAKPOINT_LARGE, GRID_BREAKPOINT_EXTRA_LARGE } from 'HubStyleTokens/sizes';
import * as ScreenSizes from '../constants/layout/ScreenSizes';
const GRID_BREAKPOINT_SMALL_INT = parseInt(GRID_BREAKPOINT_SMALL, 10);
const GRID_BREAKPOINT_MEDIUM_INT = parseInt(GRID_BREAKPOINT_MEDIUM, 10);
const GRID_BREAKPOINT_LARGE_INT = parseInt(GRID_BREAKPOINT_LARGE, 10);
const GRID_BREAKPOINT_EXTRA_LARGE_INT = parseInt(GRID_BREAKPOINT_EXTRA_LARGE, 10);

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export function useBreakpoint(width) {
  return useMemo(() => {
    if (width < GRID_BREAKPOINT_SMALL_INT) {
      return ScreenSizes.XS;
    } else if (width < GRID_BREAKPOINT_MEDIUM_INT) {
      return ScreenSizes.SM;
    } else if (width < GRID_BREAKPOINT_LARGE_INT) {
      return ScreenSizes.MD;
    } else if (width < GRID_BREAKPOINT_EXTRA_LARGE_INT) {
      return ScreenSizes.LG;
    } else {
      return ScreenSizes.XL;
    }
  }, [width]);
}