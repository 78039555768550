import { useWindowDimensionsContext } from 'marketplace-ui-common/hooks/useWindowDimensionsContext';
import { useBreakpoint } from './useBreakpoint';

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useDeviceSize = () => {
  const {
    deviceWidth
  } = useWindowDimensionsContext();
  return useBreakpoint(deviceWidth);
};