import getLang from 'I18n/utils/getLang';
import { apiClientProvider } from '../utils/apiClient';
let apiBase = 'marketplace/profiles/v1';
export const setProfilesApiBase = base => {
  apiBase = base;
};
export const searchProfiles = apiClientProvider((apiClient, query) => {
  return apiClient.post(`${apiBase}/profiles/search`, {
    data: [Object.assign({}, query, {
      callingUserLanguage: getLang()
    })]
  });
});
export const searchProfilesMulti = apiClientProvider((apiClient, queries) => {
  return apiClient.post(`${apiBase}/profiles/search`, {
    data: queries
  });
});
export const fetchProfileListing = apiClientProvider((apiClient, slug) => apiClient.get(`${apiBase}/profiles/details`, {
  query: {
    slug
  }
}));
export const fetchProfileListingPreview = apiClientProvider(apiClient => apiClient.get(`${apiBase}/profiles/details/preview`));
export const fetchUserHasProfile = apiClientProvider(apiClient => {
  return apiClient.get(`${apiBase}/profiles/existence`);
});