import { useWindowDimensionsContext } from 'marketplace-ui-common/hooks/useWindowDimensionsContext';
import { useBreakpoint } from './useBreakpoint';
import * as ScreenSizes from '../constants/layout/ScreenSizes';
const SIZES = [ScreenSizes.XS, ScreenSizes.SM, ScreenSizes.MD, ScreenSizes.LG, ScreenSizes.XL];

/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useScreenSize = () => {
  const {
    width
  } = useWindowDimensionsContext();
  return useBreakpoint(width);
};
/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useIsMinScreenSize = minSize => {
  const screenSize = useScreenSize();
  return SIZES.indexOf(minSize) <= SIZES.indexOf(screenSize);
};
/**
 * @deprecated moved to marketplace-ui-common/hooks
 */
export const useIsMaxScreenSize = maxSize => {
  const screenSize = useScreenSize();
  return SIZES.indexOf(maxSize) > SIZES.indexOf(screenSize);
};