'use es6';

import quickFetch from 'quick-fetch';
export const maybeEarlyRequest = (requestName, requestCb) => {
  const earlyRequest = requestName && quickFetch.getRequestStateByName(requestName);
  if (earlyRequest) {
    const earlyRequestPromise = new Promise((resolve, reject) => {
      earlyRequest.whenFinished(result => {
        resolve(result);
        quickFetch.removeEarlyRequest(requestName);
      });
      earlyRequest.onError(() => {
        // retry the request
        requestCb().then(resolve).catch(err => {
          reject(err);
        });
        quickFetch.removeEarlyRequest(requestName);
      });
    });
    if (window.newrelic) {
      window.newrelic.setCustomAttribute(`${requestName}-early-request-used`, Boolean(earlyRequest.finished));
    }
    return earlyRequestPromise;
  }
  return requestCb();
};