'use es6';

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as ScreenSizes from '../../constants/layout/ScreenSizes';
import { useScreenSize } from '../../hooks/useScreenSize';
import { useDeviceSize } from '../../hooks/useDeviceSize';
const screenSizeValues = Object.values(ScreenSizes);
const isSizeWithinBounds = (size, min, max) => screenSizeValues.indexOf(size) >= screenSizeValues.indexOf(min) && screenSizeValues.indexOf(size) <= screenSizeValues.indexOf(max);

/**
 * @deprecated use MediaQuery from marketplace-ui-common/layout/components/MediaQuery
 */
const MediaSize = ({
  alternate,
  children,
  minDeviceSize = ScreenSizes.XS,
  maxDeviceSize = ScreenSizes.XL,
  minScreenSize = ScreenSizes.XS,
  maxScreenSize = ScreenSizes.XL
}) => {
  const deviceSize = useDeviceSize();
  const screenSize = useScreenSize();
  const satisfiesDeviceSize = useMemo(() => isSizeWithinBounds(deviceSize, minDeviceSize, maxDeviceSize), [deviceSize, minDeviceSize, maxDeviceSize]);
  const satisfiesScreenSize = useMemo(() => isSizeWithinBounds(screenSize, minScreenSize, maxScreenSize), [screenSize, minScreenSize, maxScreenSize]);
  if (satisfiesDeviceSize && satisfiesScreenSize) {
    return children || null;
  }
  return alternate || null;
};
MediaSize.propTypes = {
  alternate: PropTypes.node,
  children: PropTypes.node,
  minDeviceSize: PropTypes.oneOf(screenSizeValues),
  maxDeviceSize: PropTypes.oneOf(screenSizeValues),
  minScreenSize: PropTypes.oneOf(screenSizeValues),
  maxScreenSize: PropTypes.oneOf(screenSizeValues)
};
export default MediaSize;